'use client';
import { Card, Image, Text, Button, Group, AspectRatio, CardSection } from '@mantine/core';

import classes from './Card.module.css';
import { IconCheckbox, IconClock, IconCurrencyRupee, IconHome, IconProgress, IconReport, IconTimelineEvent } from '@tabler/icons-react';
import Link from 'next/link';
import { useAppDispatch, useAppSelector } from "@/utils/lib/redux/hooks";
import { useRouter } from 'next/navigation';
import { setCartState } from '@/utils/lib/redux/slices/cartSlice';
import { cloneDeep, isEmpty } from 'lodash';
import { IMAGE_BASE_URL } from '@/config';

export default function CardComponent(props: any) {

    const { id, name, price, image, discount, is_home_collection, prerequisites, description, report_time, height, book_now, is_test, is_imaging } = props;

    const router = useRouter();

    const dispatch = useAppDispatch();

    const handleBookNow = (new_booking_test: any) => {
        dispatch(setCartState([new_booking_test]))
        router.push('/home/cart');
    }

    const itemsInCart = useAppSelector((state) => state.cart.cartState)

    const handleAddToCart = (additional_tests: any) => {
        let newItemsInCart = cloneDeep(itemsInCart)
        newItemsInCart.push(additional_tests);

        dispatch(setCartState(newItemsInCart));
    }

    const removeItemFromCart = (itemIdToRemove: any) => {
        let newItemsInCart = cloneDeep(itemsInCart)
        let indexOfItemToRemove = newItemsInCart.findIndex(item => item.name === itemIdToRemove);
        newItemsInCart.splice(indexOfItemToRemove, 1);
        dispatch(setCartState(newItemsInCart))
    }

    const isInCart = (checkItem: string) => {
        if (itemsInCart.find((item: any) => item.name === checkItem)) {
            return true;
        }
        return false;
    }

    return (
        <Card shadow="sm" p={15} radius="md" withBorder h={height} style={{ 'cursor': 'grab' }}>
            <CardSection component="a" href={`/details/${is_test === true ? 'test' : is_test === false ? 'health-package' : 'imaging'}/${id}`} p={20}>
                {!isEmpty(image) && !isEmpty(image.data) &&
                    <AspectRatio ratio={100 / 100} maw={100} mx="auto">
                        <Image
                            height={'100px'}
                            width={'100px'}
                            src={IMAGE_BASE_URL + image.data.attributes.url}
                            alt={name}
                            className={classes.filterblue}
                            loading='lazy'
                        />
                    </AspectRatio>
                }

                <Text fw={500} mt={10} size='sm' c={'dark'}>{name}</Text>

                {description && <Text size="xs" c="dimmed">
                    {description}
                </Text>}
            </CardSection>

            <Group justify="space-between" mt="sm" mb="xs">
                <Text fz={20} fw={600} c={"dark.10"} display={'flex'} style={{ alignItems: 'center' }}>
                    <IconCurrencyRupee /> {Math.floor(price - (price * discount) / 100)}
                    {discount != 0 && <Text td={'line-through'} component='span' ml={0} mr={5}>{price}</Text>}
                    {discount != 0 && <Text component='span' c={"green"} fw={700}>{discount}% off</Text>}
                </Text>
            </Group>

            {report_time &&
                <Text size="xs" display={'flex'} style={{ alignItems: 'center' }} >
                    <IconReport color={'teal'} />
                    <Text component='span' c='gray.8' fw={600} ml={'5px'}> {report_time} report time</Text>
                </Text>}

            {is_home_collection &&
                <Text size='xs' c={'dark'} display={'flex'} style={{ alignItems: 'center' }}>
                    <IconHome color='teal' />
                    <Text component='span' c={'gray.8'} fw={600} ml={'5px'}>Home collection available</Text>
                </Text>}

            {prerequisites &&
                <Text size="xs" display={'flex'} style={{ alignItems: 'center' }} >
                    <IconTimelineEvent color={'teal'} />
                    <Text component='span' c='gray.8' fw={600} ml={'5px'}> No special preparation required</Text>
                </Text>}

            <Group pos={'absolute'} bottom={20} >
                {isInCart(name) &&
                    <Button type="button" bg={'red.1'} c={'red'} variant="light"
                        onClick={() => removeItemFromCart(name)}
                    >Remove</Button>
                }
                {!isInCart(name) &&
                    <>
                        {book_now ? <Button variant="dark" color="blue" radius="md" onClick={() => handleBookNow({ id, name, price, discount, description, prerequisites })}>
                            Book Now
                        </Button> :
                            <Button variant="dark" color="blue" radius="md" onClick={() => handleAddToCart({ id, name, price, discount, description, prerequisites })}>
                                Add To Cart
                            </Button>
                        }
                    </>
                }
                {is_imaging ? <Link href={`/details/imaging/${id}`}>Know More</Link> : <Link href={`/details/${is_test ? 'test' : 'health-package'}/${id}`}>Know More</Link>}

            </Group>


        </Card >
    );
}
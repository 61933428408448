'use client'

import {
    Badge,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    useMantineTheme,
    Box,
} from '@mantine/core';
import { IconClockPlus, IconDiscount2, IconCircleCheck, IconHomeCheck } from '@tabler/icons-react';
import classes from './FeaturesCards.module.css';

const mockdata = [
    {
        title: 'Trusted Labs',
        description:
            'Every test booked via AvraPathCare is conducted by certified labs that are 100% verified and trustworthy.',
        icon: IconCircleCheck,
    },
    {
        title: 'Home Visit',
        description:
            'With AvraPathCare, you get a FREE sample pick-up* by professional phlebotomists from your home or preferred location.',
        icon: IconHomeCheck,
    },
    {
        title: 'Timely and Accurate Reports',
        description:
            'Once collected, samples will be sent to labs for processing. Detailed reports will be shared within a stipulated timeline.',
        icon: IconClockPlus,
    },
    {
        title: 'Affordable Pricing',
        description:
            'Get the best price for tests compared across various labs',
        icon: IconDiscount2,
    },
];

export function FeaturesCards(props: any) {
    const { isfeatures } = props;
    const theme = useMantineTheme();
    const features = mockdata.map((feature) => (
        <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl">
            <feature.icon
                style={{ width: rem(50), height: rem(50) }}
                stroke={2}
            />
            <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
                {feature.title}
            </Text>
            <Text fz="sm" c="dimmed" mt="sm">
                {feature.description}
            </Text>
        </Card>
    ));

    return (
        <Box mb={48}>
            {isfeatures &&
                <>
                    <Group justify="center">
                        <Badge variant="filled" size="lg">
                            Why Choose Avra PathCare
                        </Badge>
                    </Group>

                    <Title order={2} className={classes.title} ta="center" mt="sm">
                        We firmly believe that knowledge is power when it comes to your health.
                    </Title>

                    <Text c="dimmed" className={classes.description} ta="center" mt="md">
                        We are committed to empowering you with the knowledge you need to make informed decisions about your health and take proactive steps towards a healthier, happier life.
                    </Text>
                </>
            }

            <SimpleGrid cols={{ base: 1, md: 4 }} spacing="xl" mt={50}>
                {features}
            </SimpleGrid>
        </Box>
    );
}
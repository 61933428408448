'use client'
import { Image, Text, Container, ThemeIcon, Title, SimpleGrid, Card, Drawer, Button } from '@mantine/core';

import classes from './UploadFeatures.module.css';
import { IconFlaskFilled, IconHeartHandshake, IconPhoneCall, IconReportMedical } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useRouter } from 'next/navigation';




export function UploadFeatures() {

    const router = useRouter()
    const [opened, { open, close }] = useDisclosure(false);

    const data = [
        {
            image: <IconReportMedical />,
            title: 'Upload Prescription',
            method: () => router.push('/appointment')
        },

        {
            image: <IconFlaskFilled />,
            title: 'All Tests',
            method: () => router.push('/services/all-tests')
        },
        {
            image: <IconHeartHandshake />,
            title: 'Health Packages',
            method: () => router.push('/services/healthpackages')
        },
        {
            image: <IconPhoneCall />,
            title: 'Book On Call',
            method: open
        }
    ];

    const items = data.map((item, index) => (
        <Card shadow="sm" padding="sm" onClick={item.method} radius="md" withBorder className={classes.item} key={index}>
            <ThemeIcon variant="light" className={classes.itemIcon} size={60} radius="md">
                {item.image}
            </ThemeIcon>

            <Text fw={500} fz="sm" c={'gray.8'} className={classes.itemTitle}>
                {item.title}
            </Text>


        </Card>
    ));

    return (
        <Container size={'md'} p={0}>
            <SimpleGrid cols={{ base: 1, xs: 2, md: 4 }} spacing={{ xs: 30, sm: 30, md: 50 }}>
                {items}
            </SimpleGrid>

            <Drawer opened={opened} position='right' onClose={close} >
                <h3>Book On Call</h3>
                Give a call on +919893418292 and our health experts will help you with your test booking.
                <br></br>
                <br></br>
                <Button fz={18}>
                    <a style={{ 'color': '#fff', "fontSize": "18px" }} href='tel:+919893418292'>Call Now</a>
                </Button>


            </Drawer>
        </Container>
    );
}

const API_BASE_URL = process.env.NODE_ENV === 'production' ? 'https://admin.avrapathcarelab.com' : 'http://127.0.0.1:1337';
const BASE_HREF = process.env.NODE_ENV === 'production' ? 'https://avrapathcarelab.com' : 'http://localhost:3000';
const API_KEY: string | undefined = process.env.API_KEY;

// service urls
const TEST_URL: string = `${API_BASE_URL}/api/tests?pagination[start]=0&pagination[limit]=500`;
const TEST_DETAILS: string = `${API_BASE_URL}/api/tests`;
const PACKAGE_DETAILS: string = `${API_BASE_URL}/api/health-packages`;
const IMAGING_DETAILS: string = `${API_BASE_URL}/api/imagings`;
const CATEGORY_URL: string = `${API_BASE_URL}/api/categories?populate=*&pagination[start]=0&pagination[limit]=1000`;
const BLOG_URL: string = `${API_BASE_URL}/api/blogs?populate=*`;
const PACKAGE_URL: string = `${API_BASE_URL}/api/health-packages?populate=*`;
const ME_URL: string = `${API_BASE_URL}/api/users/me?populate=*`
const IMAGING_URL: string = `${API_BASE_URL}/api/imagings?populate=*`;
const SEARCH_URL: string = `${API_BASE_URL}/api/fuzzy-search/search`
// For post url
const NEXT_BOOKING_URL: string = `${BASE_HREF}/api/booking`;
const NEXT_PRESCRIPTION_URL: string = `${BASE_HREF}/api/prescription`;

const IMAGE_BASE_URL: string = `${API_BASE_URL}`;
// Sizes: w300, w780, w1280, original
const BACKDROP_SIZE: string = 'w1280';
// w92, w154, w185, w342, w500, w780, original
const POSTER_SIZE: string = 'w780';

export {
  TEST_URL,
  BLOG_URL,
  API_BASE_URL,
  API_KEY,
  IMAGE_BASE_URL,
  BACKDROP_SIZE,
  POSTER_SIZE,
  PACKAGE_URL,
  NEXT_BOOKING_URL,
  NEXT_PRESCRIPTION_URL,
  ME_URL,
  CATEGORY_URL,
  TEST_DETAILS,
  IMAGING_URL,
  PACKAGE_DETAILS,
  IMAGING_DETAILS,
  SEARCH_URL
};

'use client';
import { useForm } from '@mantine/form';

import {
    TextInput,
    Text,
    Paper,
    Group,
    PaperProps,
    Button,
    Stack,
    NumberInput,
    Notification,
    Loader,
    Box,
    Textarea
} from '@mantine/core';
import { useAppDispatch, useAppSelector } from "@/utils/lib/redux/hooks";
import { DatePickerInput } from '@mantine/dates';
import { useEffect, useState } from 'react';
import { NEXT_BOOKING_URL } from '@/config';
import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { resetCart } from '@/utils/lib/redux/slices/cartSlice';


export function BookingForm(props: any) {


    const [shownotification, setShowNotification] = useState(false);
    const [successNot, setNotificationSuccess] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const { data: session, status } = useSession();
    const router = useRouter();
    const dispatch = useAppDispatch();

    const form = useForm({
        initialValues: {
            email: '',
            name: '',
            address: '',
            booking: new Date(),
            userid: 0
        },

        validate: {
            email: (val: string) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email')
        },
    });

    useEffect(() => {
        if (status == 'authenticated') {
            form.setFieldValue('email', session.user.email);
            form.setFieldValue('name', session.user.name || '')
            form.setFieldValue('userid', session.id);
        }
    }, [status])




    const submitForm = async (formData: any) => {
        if (status == 'authenticated') {
            setLoading(true);
            let bodyData = formData;

            if (props.cartData) {
                let testIds = props.cartData.map((item: any) => item.id);
                bodyData = { ...bodyData, tests: testIds, booking_cost: props.booking_cost }
            } else {
                bodyData = { ...bodyData, tests: [], booking_cost: 0 }
            }

            const res = await fetch(NEXT_BOOKING_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session?.jwt}`
                },
                body: JSON.stringify(bodyData)
            })

            const data = res;

            if (data.ok) {
                setLoading(false);
                setNotificationSuccess(true);
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                    form.onReset;
                }, 3000);
                dispatch(resetCart())
            } else {
                setNotificationSuccess(false);
                setLoading(false);
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                }, 3000);

            }
        } else {
            signIn()
        }



    }

    return (
        <Box p="md" bg={'white'} >
            <Text size="xl" mb={'md'} fw={500} ta={'center'}>
                Schedule a Home Collection
            </Text>

            <form onSubmit={form.onSubmit((values) => submitForm(values))}>
                <Stack>
                    <TextInput
                        required
                        label="Name"
                        placeholder="Enter Name"
                        value={form.values.name}
                        onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
                        radius="md"
                    />


                    <TextInput
                        required
                        label="Email"
                        placeholder="abc@gmail.com"
                        value={form.values.email}
                        onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                        error={form.errors.email && 'Invalid email'}
                        radius="md"
                    />

                    <NumberInput
                        required
                        label="Number"
                        placeholder="Enter Phone Number"
                        prefix="+91"
                        maxLength={13}
                        onChange={(event) => form.setFieldValue('phonenumber', event)}
                        radius="md"
                    />
                    <DatePickerInput
                        required
                        label="Booking Date"
                        valueFormat="DD/MM/YYYY"
                        value={form.values.booking}
                        onChange={(event) => form.setFieldValue('booking', new Date(`${event}`))}
                        clearable
                        minDate={new Date()}
                    />
                    <Textarea
                        required
                        label="Address"
                        placeholder=""
                        value={form.values.address}
                        onChange={(event) => form.setFieldValue('address', event.currentTarget.value)}
                        radius="md"
                    />
                </Stack>

                <Group justify="center" mt="xl">
                    <Button type="submit" h={'auto'} p={20} fz={'1.1rem'} radius="sm" disabled={isLoading}>
                        Book Appointment {isLoading && <Loader ml={10} size={20} color="blue" />}
                    </Button>
                </Group>
            </form>

            {shownotification && <Notification color={successNot ? 'green' : 'red'} title={successNot ? 'Booking successfull' : 'Booking failed'}>
                {successNot ? 'We will contact you soon.' : 'Please try again in some time'}
            </Notification>}
        </Box>
    );
}
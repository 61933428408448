'use client'

import { SimpleGrid, Card, Image, Text, Container, AspectRatio, Box } from '@mantine/core';
import classes from './Blog.module.css';
import { IMAGE_BASE_URL } from '@/config';


export function BlogCard(props: any) {

    const { blogs, } = props;

    return (
        <Box p={0}>
            <SimpleGrid cols={{ base: 1, sm: 2, md: 3 }}>
                {
                    blogs.map((blogs: any, index: any) => (
                        <Card key={blogs.attributes.title + blogs.attributes.index} radius="md" component="a" href="#" className={classes.card}>
                            <AspectRatio ratio={1920 / 1080}>
                                <Image loading='lazy' src={IMAGE_BASE_URL + blogs.attributes.image.data.attributes.url} />
                            </AspectRatio>
                            <Text c="dimmed" size="xs" tt="uppercase" fw={700} mt="md">
                                {blogs.attributes.createdAt.slice(0, 10)}
                            </Text>
                            <Text className={classes.title} mt={5}>
                                {blogs.attributes.title}
                            </Text>
                        </Card>
                    ))
                }
            </SimpleGrid>
        </Box>
    );
}